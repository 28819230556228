import type { Dict, Mixpanel } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import { until } from '@vueuse/core';

const MIXPANEL_INSTANCE_KEY = 'mixpanel-instance';

export function useMixpanel() {
	const { mixpanelToken } = useRuntimeConfig().public;

	const mixpanelInstance = useState<Mixpanel | null>(
		MIXPANEL_INSTANCE_KEY,
		() => null
	);

	return {
		init() {
			// eslint-disable-next-line
			mixpanelInstance.value = mixpanel.init(
				mixpanelToken,
				{ debug: import.meta.env.DEV },
				MIXPANEL_INSTANCE_KEY
			);
		},
		async register(props: Dict, days?: number) {
			const mixpanel = await until(mixpanelInstance).toBeTruthy();

			mixpanel.register(props, days);
		},
		async track(eventName: string, properties?: Dict) {
			const mixpanel = await until(mixpanelInstance).toBeTruthy();

			mixpanel.track(eventName, properties);
		}
	};
}
