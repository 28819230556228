import { default as _91_91minisiteId_93_93ZxWH21FcYbMeta } from "/builds/blintz/front-web/minisite-nuxt/minisite/pages/[[minisiteId]].vue?macro=true";
import { default as changelogelbW71OgOIMeta } from "/builds/blintz/front-web/minisite-nuxt/minisite/pages/changelog.vue?macro=true";
export default [
  {
    name: "PropertiesPage",
    path: "/:minisiteId?",
    alias: ["/"],
    component: () => import("/builds/blintz/front-web/minisite-nuxt/minisite/pages/[[minisiteId]].vue").then(m => m.default || m)
  },
  {
    name: "changelog",
    path: "/changelog",
    component: () => import("/builds/blintz/front-web/minisite-nuxt/minisite/pages/changelog.vue").then(m => m.default || m)
  }
]