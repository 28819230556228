export const queryStringParams = {
	CLIENT_NAME: 'n'
} as const;

export const mixpanelEvents = {
	CLOSE_PROPERTY_CARD: 'Fechar card',
	CLOSE_CAROUSEL_MODAL: 'Fechar modal de fotos',
	COPY_COMMERCIAL_ID: 'Copiar ID comercial',
	ENTER_MINISITE: 'Entrar no minisite',
	MOVE_CARD_CAROUSEL: 'Passar carrossel do card',
	MOVE_MODAL_CAROUSEL: 'Passar carrossel do modal',
	EXPAND_PROPERTY_CARD: 'Abrir card',
	OPEN_CAROUSEL_MODAL: 'Abrir modal de fotos',
	SELECT_TEXT: 'Selecionar texto',
	CLICK_HEADER_LOGO: 'Clicar no logo da header',
	CLICK_PARTNER_LOGO: 'Clicar no logo de parceiros do header',
	CLICK_CTA_BUTTON: 'Clicar no botão da CTA de redirecionamento para o site',
	CLICK_PHONE_NUMBER: 'Clicar no telefone',
	CLICK_EXPIRATION_MODAL_BUTTON: 'Clicar no link do modal de expiração',
	MINISITE_EXPIRED: 'Ver modal de expiração',
	MOUSE_LEAVE_DOCUMENT: 'Retirar o mouse da tela',
	MOUSE_ENTER_DOCUMENT: 'Retornar o mouse para tela',
	GO_TO_ANOTHER_TAB: 'Mudar a aba no browser',
	RETURN_TO_CURRENT_TAB: 'Voltar para a aba no browser',
	CLICK_ON_PROPERTY_CARD_SELECTION_BUTTON: 'Seletor de imóvel',
	CLICK_ON_PROPERTIES_SELECTION_CTA: 'CTA Agendar visita',
	REQUEST_PROPERTIES_RECOMMENDATION: 'Solicitar recomendação de imóveis',
	SHOW_MORE_RECOMMENDED_PROPERTIES: 'Ver mais imóveis recomendados',
	OPEN_SCHEDULE_VISIT_MODAL: 'Modal de agendamento',
	SHARE_MINISITE: 'Compartilhamento do mini',
	CTA_CLICK: 'CTA Click'
} as const;

export const mixpanelParams = {
	CLIENT_NAME: 'Nome do cliente',
	COPIED_TEXT: 'Texto copiado',
	DOMAIN: 'Domínio',
	IMAGE_URL: 'URL da foto',
	MINISITE_ID: 'ID do minisite',
	OPEN_IMAGE_URL: 'URL da foto aberta',
	PROPERTY_COMMERCIAL_ID: 'ID do imóvel',
	COMPANY_NAME: 'Nome da imobiliária',
	NUMBER_OF_PROPERTIES: 'Número de imóveis',
	NUMBER_OF_PROPERTIES_ON_SELECTION_LIST: 'Número de imóveis selecionados',
	TYPE: 'Tipo',
	PROPERTY_WAS_ADDED_TO_SELECTION_LIST: 'Adicionou',
	PROPERTY_WAS_REMOVED_FROM_SELECTION_LIST: 'Removeu',
	COMMERCIAL_IDS_FROM_PROPERTIES_ON_SELECTION_LIST: 'Imóveis selecionados',
	VERSION: 'Versão do sistema',
	CONTACT_PHONE: 'Telefone',
	FEATURE_FLAGS_LIST: 'Feature flags ativas',
	PROPERTY_CARD_OPENING_TRIGGER: 'Gatilho de abertura do card',
	RETRY: 'Retry',
	NUMBER_OF_RECOMMENDED_PROPERTIES: 'Número de imóveis recomendados',
	NUMBER_OF_RECOMMENDED_PROPERTIES_ON_SELECTION_LIST:
		'Número de imóveis recomendados selecionados',
	NUMBER_OF_DISPLAYED_RECOMMENDED_PROPERTIES:
		'Número de imóveis recomendados exibidos',
	IS_A_RECOMMENDED_PROPERTY: 'É um imóvel recomendado',
	PROPERTY_POSITION: 'Posição do Card',
	PROPERTY_SOURCE: 'Origem do imóvel',
	SHARE_CLICK: 'Clique no compartilhar'
} as const;

export const shareLinkTrigger: Record<string, string> = {
	menu: 'Menu',
	header: 'Header',
	'expanded-property-card': 'Dentro do card aberto'
};
