import revive_payload_client_6VaVRlQIeU from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EObmiQLDfP from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YmwIBja3hS from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0KAm9aqEZJ from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TL4IcFi1jD from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dKm7CNTPNv from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Aqm9Jh4anQ from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/blintz/front-web/minisite-nuxt/minisite/.nuxt/components.plugin.mjs";
import prefetch_client_2KNaQJ57gK from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.42_encoding@0.1.13_eslint@8.57.0_ioredis@_3twx6zpil6qiw4xcgtjfzyqqhi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_gSuI5Z8hoP from "/builds/blintz/front-web/minisite-nuxt/node_modules/.pnpm/@nargarath+vue-recaptcha@3.0.0-alpha.7_magicast@0.3.4_rollup@4.19.1_vue@3.4.35_typescript@4.9.5_/node_modules/@nargarath/vue-recaptcha/dist/nuxt-plugin.mjs";
import _01_sentry_client_2rh1pDAIty from "/builds/blintz/front-web/minisite-nuxt/minisite/plugins/01.sentry.client.ts";
import _02_hotjar_client_lRV5SYebEg from "/builds/blintz/front-web/minisite-nuxt/minisite/plugins/02.hotjar.client.ts";
import _05_ctaInteractionTracker_TPgDeMJ1wt from "/builds/blintz/front-web/minisite-nuxt/minisite/plugins/05.ctaInteractionTracker.ts";
export default [
  revive_payload_client_6VaVRlQIeU,
  unhead_EObmiQLDfP,
  router_YmwIBja3hS,
  payload_client_0KAm9aqEZJ,
  navigation_repaint_client_TL4IcFi1jD,
  check_outdated_build_client_dKm7CNTPNv,
  chunk_reload_client_Aqm9Jh4anQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2KNaQJ57gK,
  nuxt_plugin_gSuI5Z8hoP,
  _01_sentry_client_2rh1pDAIty,
  _02_hotjar_client_lRV5SYebEg,
  _05_ctaInteractionTracker_TPgDeMJ1wt
]