<template>
	<div data-page>
		<HeaderSection
			v-bind="header.config"
			:links="headerLinks"
			:social-media-links="[]"
			:transparent-on-top-of-page="false"
			is-minisite-context
			@click-link="handleHeaderLinkClick"
		/>
		<NuxtPage :class="pagePaddingClass" />

		<FooterSection v-bind="footerProps" />
	</div>
</template>

<script setup lang="ts">
import { useMounted, useWindowScroll } from '@vueuse/core';

import type {
	BlockableRouteNames,
	WebsiteConfig
} from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize } from '@SHARED/utils/helperTypes';
import type {
	HeaderSectionLink,
	HeaderSection as IHeaderSection
} from '@SHARED/core/entities/sections/HeaderSection';
import type { FooterSection as IFooterSection } from '@SHARED/core/entities/sections/FooterSection';

import { useAnimation } from '@SHARED/composables/useAnimation';
import { polishedColorService } from '@SHARED/adapters/services/color/polishedColorServiceAdapter';
import { phoneService } from '@SHARED/adapters/services/phone/phoneServiceAdapter';
import {
	COLOR_SERVICE,
	PHONE_SERVICE,
	PROPERTIES_REPOSITORY
} from '@SHARED/utils/vueProvidersSymbols';
import { getCssNumberValueInPixels } from '@SHARED/utils/style';
import { getUrlWithHttps, concatUrlPath } from '@SHARED/utils/url';
import { DEFAULT_WEBSITE_CONFIG } from '@SHARED/utils/websiteConfig';
import { usePilarWebsiteUtm } from '@SHARED/composables/usePilarWebsiteUtm';

import type { LinkClickSource } from '@SHARED/components/sections/HeaderSection.vue';
import HeaderSection from '@SHARED/components/sections/HeaderSection.vue';
import FooterSection from '@SHARED/components/sections/FooterSection.vue';

import { shareLinkTrigger } from '@/utils/enums';
import { nuxtPropertiesRepository as mockedPropertiesRepository } from '@/adapters/repositories/nuxt/nuxtPropertiesRepository';

provide(COLOR_SERVICE, polishedColorService);
provide(PHONE_SERVICE, phoneService);
provide(PROPERTIES_REPOSITORY, mockedPropertiesRepository);

const nuxtAppData = useNuxtApp();
const mixpanel = useMixpanel();

const domain = useState(
	'domain',
	() => nuxtAppData.ssrContext!.event.node.req.headers.host
);

const websiteConfig = useState<WebsiteConfig>('websiteConfig');

useHeadSafe({
	htmlAttrs: {
		lang: 'pt-BR'
	},
	title: websiteConfig.value.company.name,
	link: [
		{
			rel: 'icon',
			type: 'image/svg+xml',
			href: websiteConfig.value.meta.favicon || ''
		}
	],
	bodyAttrs: {
		class: 'min-h-screen w-full'
	}
});

const minisiteUrl = computed<string | null>(() => {
	if (!domain.value) return null;

	return getUrlWithHttps(domain.value);
});

useServerSeoMeta({
	title: 'Seleção de imóveis',
	ogTitle: 'Seleção de imóveis',
	description: 'Selecionei esses imóveis especialmente para você.',
	ogDescription: 'Selecionei esses imóveis especialmente para você.',
	ogImage: websiteConfig.value.meta.image,
	ogUrl: minisiteUrl.value
});

const header = computed<IHeaderSection>(() => websiteConfig.value.header);

const { y: yPosition } = useWindowScroll();

const blockedRoutes = useState<BlockableRouteNames[]>('blockedRoutes');

const { utmParams } = usePilarWebsiteUtm({ context: 'minisite' });

const isDefaultWebsiteConfig = computed(
	() => websiteConfig.value.company.name === DEFAULT_WEBSITE_CONFIG.company.name
);

const companyWebsite = computed(() => websiteConfig.value?.company?.website);

function getContactURL() {
	if (isDefaultWebsiteConfig.value) return null;

	const hasContactRoute = !blockedRoutes.value.includes('ContactPage');
	const hasContactSection = footer.value.config.contactSection;

	if (hasContactRoute) {
		return `${companyWebsite.value}/contato?${utmParams.value}`;
	}

	if (hasContactSection) {
		return `${companyWebsite.value}/#contato`;
	}

	return null;
}

function getShareURL() {
	const router = useRouter();

	const minisiteUrl = concatUrlPath(
		domain.value || '',
		router.currentRoute.value.path
	);

	const message = `Dá uma olhada nesses imóveis que estou considerando. Gostaria de saber sua opinião: ${minisiteUrl}`;
	const params = new URLSearchParams({ text: message });

	return `https://api.whatsapp.com/send?${params}`;
}

function getSiteUrl() {
	const url = `${companyWebsite.value}?${utmParams.value}`;

	return isDefaultWebsiteConfig.value ? null : url;
}

interface HeaderSectionTemplate extends HeaderSectionLink {
	get?: () => string | null;
}

const linksTemplate: HeaderSectionTemplate[] = [
	{
		text: 'Visite o site',
		url: '',
		get: () => getSiteUrl()
	},
	{
		text: 'Compartilhar',
		url: '',
		target: '_blank',
		get: () => getShareURL()
	},
	{
		text: 'Entrar em contato',
		url: '',
		get: () => getContactURL()
	}
];

const isPartnershipMinisite = useState<boolean>(
	'isPartnershipMinisite',
	() => false
);

const isMounted = useMounted();

const headerLinks = computed<HeaderSectionLink[]>(() =>
	linksTemplate.reduce((links: HeaderSectionLink[], link) => {
		if (
			// <!-- TODO: gambiarra pra contornar um bug no Nuxt que gera erro de hydration mismatch  -->
			isMounted.value &&
			isPartnershipMinisite.value &&
			link.text.toLowerCase().includes('compartilhar')
		) {
			return links;
		}

		const { get, ...linkOptions } = link;

		const url = typeof get === 'function' ? get() : linkOptions.url;

		if (!url) return links;

		return [
			...links,
			{
				...linkOptions,
				url
			}
		];
	}, [])
);

function trackShareButtonClick(trigger: LinkClickSource) {
	mixpanel.track(mixpanelEvents.SHARE_MINISITE, {
		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: null,
		[mixpanelParams.SHARE_CLICK]: shareLinkTrigger[trigger]
	});
}

function handleHeaderLinkClick({
	link,
	source
}: {
	link: HeaderSectionLink;
	source: LinkClickSource;
}) {
	if (link.text === 'Compartilhar') trackShareButtonClick(source);
}

const { getAnimationClass } = useAnimation({
	animationStatus: 'start',
	animationStyle: 'page-padding'
});

const headerHeight = computed(() => {
	if (!header.value) return 0;

	return header.value.config.height;
});

const pagePaddingClass = computed<string>(() => {
	const headerHeightValue = getCssNumberValueInPixels(
		headerHeight.value || '0px'
	);

	const animationStatus = yPosition.value < headerHeightValue ? 'start' : 'end';

	return getAnimationClass(animationStatus);
});

const footer = computed<IFooterSection>(() => websiteConfig.value.footer);

const footerProps = computed<IFooterSection['config']>(() => {
	const footerConfig = footer.value.config;

	return {
		...footerConfig,
		variant: 'minisite',
		isMinisiteContext: true,
		aboutSection: null,
		contactSection: null
	};
});

// <!-- ! Gambiarra pra contornar um bug no Nuxt/Vue -->
const {
	primary: primaryColor,
	secondary: secondaryColor,
	accent: accentColor,
	black: blackColor,
	white: whiteColor,
	offwhite: offwhiteColor,
	success: successColor,
	danger: dangerColor,
	lightgray: lightgrayColor,
	gray: grayColor,
	darkgray: darkgrayColor
} = websiteConfig.value.styles.colorPalette;

const { fontFamily: defaultFontFamily } =
	websiteConfig.value.styles.typography.default;

const { fontFamily: headingFontFamily } =
	websiteConfig.value.styles.typography.heading;

const sitePaddingMobile = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.mobile || '5%'
);

const sitePaddingTablet = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.tablet || '7.5%'
);

const sitePaddingDesktop = computed<CssSize>(
	() => websiteConfig.value.styles.defaultContainerPadding?.desktop || '10%'
);

useState('header', () => header);
useState('footer', () => footer);
</script>

<style lang="postcss">
[data-page] {
	position: relative;

	--sm-site-padding: v-bind(sitePaddingMobile);
	--md-site-padding: v-bind(sitePaddingTablet);
	--lg-site-padding: v-bind(sitePaddingDesktop);

	--header-height: v-bind(headerHeight);

	--primary: v-bind(primaryColor);
	--secondary: v-bind(secondaryColor);
	--accent: v-bind(accentColor);

	--black: v-bind(blackColor);
	--white: v-bind(whiteColor);
	--offwhite: v-bind(offwhiteColor);
	--danger: v-bind(dangerColor);
	--success: v-bind(successColor);
	--lightgray: v-bind(lightgrayColor);
	--gray: v-bind(grayColor);
	--darkgray: v-bind(darkgrayColor);

	--default-font: v-bind(defaultFontFamily), Ubuntu, system-ui, sans-serif;
	--heading-font: v-bind(headingFontFamily), TimesNewRoman, 'Times New Roman',
		Times, Baskerville, Georgia, serif;

	.container {
		display: flex;
		width: 100%;
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;

		padding-left: var(--sm-site-padding);
		padding-right: var(--sm-site-padding);

		@include screen-up(md) {
			padding-left: var(--md-site-padding);
			padding-right: var(--md-site-padding);
		}

		@include screen-up(lg) {
			padding-left: var(--lg-site-padding);
			padding-right: var(--lg-site-padding);
		}
	}

	.heading-font {
		font-family: var(--heading-font);
	}

	.default-font {
		font-family: var(--default-font);
	}
}

.translate-y-0 {
	transform: translateY(0);
}

.translate-y-full {
	transform: translateY(100%);
}

.-translate-y-full {
	transform: translateY(-100%);
}

.-translate-x-full {
	transform: translateX(-100%);
}

.translate-x-full {
	transform: translateX(100%);
}

.translate-x-0 {
	transform: translateX(0);
}

.page-padding {
	transition: all 0.2s ease-in-out;
	padding-top: 0;
}

.page-padding.active {
	padding-top: var(--header-height);
}

[data-no-scroll] {
	overflow: hidden;
}
</style>
