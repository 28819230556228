import type { PropertiesRepository } from '@SHARED/core/ports/repositories/PropertiesRepository';
import { Result } from '@SHARED/core/helpers/result';

export const nuxtPropertiesRepository: PropertiesRepository = {
	getPropertiesSharedAtCompanyWebsite() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source: 'nuxtPropertiesRepository.getPropertiesSharedAtCompanyWebsite',
				originalErrorObject: null
			})
		);
	},
	getPropertyBySlug() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source: 'nuxtPropertiesRepository.getPropertyBySlug',
				originalErrorObject: null
			})
		);
	},
	getAvailablePropertyAddresses() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source: 'nuxtPropertiesRepository.getAvailablePropertyAddresses',
				originalErrorObject: null
			})
		);
	},
	getAvailablePropertyTypes() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source: 'nuxtPropertiesRepository.getAvailablePropertyTypes',
				originalErrorObject: null
			})
		);
	},
	getSitemapPropertiesSharedAtCompanyWebsite() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source:
					'nuxtPropertiesRepository.getSitemapPropertiesSharedAtCompanyWebsite',
				originalErrorObject: null
			})
		);
	},
	getRelatedPropertiesDataInPropertyRegion() {
		return Promise.resolve(
			Result.error({
				message: 'Method not implemented on the front end',
				source:
					'nuxtPropertiesRepository.getRelatedPropertiesDataInPropertyRegion',
				originalErrorObject: null
			})
		);
	}
};
