import type { Directive, DirectiveBinding } from 'vue';
import type { PropertySource } from '@SHARED/core/entities/sections/PropertyListingSection';

export type CtaActionType =
	| 'schedule-visit'
	| 'talk-to-broker'
	| 'whatsapp'
	| 'make-partnership';

export type CtaTriggerSource =
	| 'listing'
	| 'property-card'
	| 'expanded-property-card'
	| 'gallery'
	| 'broker-info';

export type CtaTrackingParams = {
	commercialId?: string;
	propertySource?: PropertySource;
	action: CtaActionType;
	trigger: CtaTriggerSource;
};

interface CtaHTMLElement extends HTMLElement {
	__ctaValue__?: any;
	__ctaClickHandler__?: (event: Event) => void;
}

export function declareVCtaDirective(
	clickHandler: (param: CtaTrackingParams) => void
): Directive<CtaHTMLElement, CtaTrackingParams> {
	return {
		mounted(
			el: CtaHTMLElement,
			{ value }: DirectiveBinding<CtaTrackingParams>
		) {
			el.__ctaValue__ = value;
			el.__ctaClickHandler__ = () => {
				clickHandler(el.__ctaValue__);
			};
			el.addEventListener('click', el.__ctaClickHandler__);
		},
		updated(
			el: CtaHTMLElement,
			{ value }: DirectiveBinding<CtaTrackingParams>
		) {
			if (JSON.stringify(el.__ctaValue__) !== JSON.stringify(value)) {
				el.__ctaValue__ = value;
			}
		},
		unmounted(el: CtaHTMLElement) {
			if (el.__ctaClickHandler__) {
				el.removeEventListener('click', el.__ctaClickHandler__);
			}
			delete el.__ctaClickHandler__;
			delete el.__ctaValue__;
		}
	};
}
