import type { PropertySource } from '@SHARED/core/entities/sections/PropertyListingSection';
import type {
	CtaTriggerSource,
	CtaActionType,
	CtaTrackingParams
} from '@SHARED/utils/declareVCtaDirective';
import { declareVCtaDirective } from '@SHARED/utils/declareVCtaDirective';
import { mixpanelEvents } from '@/utils/enums';

const ctaTriggerDescriptions: Record<CtaTriggerSource, string> = {
	listing: 'Listagem de imóveis',
	'property-card': 'Dentro do card fechado',
	'expanded-property-card': 'Dentro do card aberto',
	gallery: 'Dentro da galeria',
	'broker-info': 'Informação do corretor'
};

const ctaActionDescriptions: Record<CtaActionType, string> = {
	'schedule-visit': 'Agendar visita',
	'talk-to-broker': 'Falar com o corretor',
	whatsapp: 'Clique no WhatsApp',
	'make-partnership': 'Clique no realizar parceria'
};

const propertySourceDescriptions: Record<PropertySource, string> = {
	base: 'Imóvel próprio',
	recommendation: 'Recomendação'
};

function generateCtaTrackingData(
	actionType: CtaActionType,
	triggerSource: CtaTriggerSource
): Record<string, string> {
	const actionTypes: CtaActionType[] = [
		'schedule-visit',
		'talk-to-broker',
		'whatsapp',
		'make-partnership'
	];

	return actionTypes.reduce(
		(finalData, curActionType) => ({
			...finalData,
			[ctaActionDescriptions[curActionType]]:
				curActionType === actionType
					? ctaTriggerDescriptions[triggerSource]
					: null
		}),
		{}
	);
}

export default defineNuxtPlugin(nuxtApp => {
	function clickHandler({
		commercialId,
		propertySource,
		action,
		trigger
	}: CtaTrackingParams) {
		const mixpanel = useMixpanel();
		const params = generateCtaTrackingData(action, trigger);

		const source = propertySource
			? propertySourceDescriptions[propertySource]
			: null;

		mixpanel.track(mixpanelEvents.CTA_CLICK, {
			[mixpanelParams.PROPERTY_COMMERCIAL_ID]: commercialId || null,
			[mixpanelParams.PROPERTY_SOURCE]: source,
			...params
		});
	}

	nuxtApp.vueApp.directive('cta', declareVCtaDirective(clickHandler));
});
