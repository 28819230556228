import type { WebsiteConfig } from '@SHARED/core/entities/WebsiteConfig';
import type { CompanyWithoutWebsiteConfig } from '@SHARED/core/entities/Company';

export const DEFAULT_WEBSITE_CONFIG: WebsiteConfig = {
	company: {
		id: '',
		name: 'Pilar',
		creci: '',
		phone: '',
		email: '',
		website: 'https://www.soupilar.com.br',
		socialMediaList: []
	},
	meta: {
		title: null,
		description: null,
		image: null,
		keywords: null,
		favicon:
			'https://minisites-assets.s3.us-east-2.amazonaws.com/minisites.soupilar.com.br/new-favicon.svg'
	},
	styles: {
		colorPalette: {
			primary: '#000000',
			secondary: '#ffffff',
			accent: '#0a0a0a',
			black: '#000',
			white: '#fff',
			offwhite: '#f5f5f5',
			danger: '#ed5977',
			success: '#4bb543',
			lightgray: '#e5e5e5',
			gray: '#B7B7B7',
			darkgray: '#404040'
		},
		appearance: {
			background: 'white',
			text: 'black',
			defaultBorderRadius: 'square'
		},
		typography: {
			heading: {
				fontFamily: 'Inter'
			},
			default: {
				fontFamily: 'Roboto'
			},
			secondary: {
				fontFamily: 'Roboto'
			}
		},
		defaultContainerPadding: null,
		defaultContainerMaxWidth: null
	},
	pages: [],
	blockedRoutes: [],
	header: {
		baseType: 'section',
		sectionName: 'HeaderSection',
		config: {
			variant: 'logo-left',
			logo: {
				imageUrl:
					'https://cdn.prod.website-files.com/64da2ef608672efcb687f22a/663148a92d23f454391e38b5_Group%2016.svg',
				destinationUrl: '/',
				desktop: {
					width: '150px',
					height: 'auto'
				},
				mobile: {
					width: '120px',
					height: 'auto'
				}
			},
			links: [],
			showMenu: false,
			backgroundColor: 'black',
			textColor: 'white',
			sidebarBackgroundColor: 'black',
			sidebarTextColor: 'white',
			height: '6rem'
		}
	},
	footer: {
		baseType: 'section',
		sectionName: 'FooterSection',
		config: {
			variant: 'minisite',
			textColor: 'white',
			backgroundColor: 'black',
			aboutSection: null,
			contactSection: null
		}
	},
	sections: [],
	floatingAction: null,
	integrations: {
		googleAnalyticsId: null,
		googleTagManagerId: null
	},
	globalSettings: {
		enableBreadcrumb: false,
		enableCondos: false,
		pilarPartnerLogo: null,
		enableDynamicPropertiesListingPageMetadata: false,
		enableVisitScheduling: true,
		propertyCardConfig: {
			roomsIconsColor: null
		},
		contactConfig: {
			phone: null,
			whatsapp: null,
			email: null
		}
	}
} as const;

export function setDefaultConfigCompanyData(
	company: CompanyWithoutWebsiteConfig
) {
	return {
		...DEFAULT_WEBSITE_CONFIG,
		company: {
			...DEFAULT_WEBSITE_CONFIG.company,
			...company
		}
	};
}
