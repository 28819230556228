import * as Sentry from '@sentry/vue';

import projectSetting from '@/package.json';

export default defineNuxtPlugin(({ vueApp, $config }) => {
	const environment = $config.public.environment;

	if (environment === 'development') return;

	Sentry.init({
		app: [vueApp],
		release: `minisite@${projectSetting.version}`,
		dsn: $config.public.sentryDsn,
		environment,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(
					vueApp.$nuxt.$router
				)
			}),
			new Sentry.Replay({
				maskAllInputs: false,
				maskAllText: false,
				blockAllMedia: false
			})
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		autoSessionTracking: true,
		trackComponents: true
	});
});
