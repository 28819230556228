import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPilarChevronDown, LazyPilarChevronLeft, LazyPilarChevronRight, LazyPilarCopy, LazyPilarCopyThin, LazyPilarEmail, LazyPilarFacebook, LazyPilarHouseCheck, LazyPilarInstagram, LazyPilarLinkedin, LazyPilarLoadingSpinner, LazyPilarWhatsapp, LazyPilarYoutube, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["PilarChevronDown", LazyPilarChevronDown],
["PilarChevronLeft", LazyPilarChevronLeft],
["PilarChevronRight", LazyPilarChevronRight],
["PilarCopy", LazyPilarCopy],
["PilarCopyThin", LazyPilarCopyThin],
["PilarEmail", LazyPilarEmail],
["PilarFacebook", LazyPilarFacebook],
["PilarHouseCheck", LazyPilarHouseCheck],
["PilarInstagram", LazyPilarInstagram],
["PilarLinkedin", LazyPilarLinkedin],
["PilarLoadingSpinner", LazyPilarLoadingSpinner],
["PilarWhatsapp", LazyPilarWhatsapp],
["PilarYoutube", LazyPilarYoutube],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
